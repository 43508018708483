import { HeroSection } from './HeroSection'
import { NavBar } from './NavBar'
import { TrustedBySection } from './TrustedBySection'
import { SensorExpandingSection } from './SensorExpandingSection'
import { SellingPoints } from './SellingPoints'
import { Footer } from './footer'
import './marketing.css'
import { ReactComponent as Wave } from './wave.svg'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom';


import React, { useState, useEffect } from 'react';

export function MarketingPage(){
	const largeScreen = useMediaQuery({ query: '(max-width: 1400px)' })

	const [scrollPosition, setScrollPosition] = useState(0);
	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
		console.log(position)
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, true);
		console.log("added")
		return () => {
			window.removeEventListener('scroll', handleScroll);
			console.log("removed")
		};
	}, []);

	return (
		<div id="MarketingPage">
			<NavBar scrollPosition={scrollPosition}/>
			<HeroSection/>
			{
				false?<TrustedBySection/>:<></>
			}
			{
				!largeScreen?<SensorExpandingSection scrollPosition={scrollPosition}/>:<></>
			}
			<Link to="/building?building=1">DEMO</Link>
			<Wave style={{width:"100%"}}/>
			<SellingPoints/>
			<div className='m_get_in_touch_section' id='contact'>
				<p>Get In Touch</p>
				<form className='m_get_in_touch_container' action="https://formsubmit.co/03e41621621b634a8b6de61f8bc0923d " method="POST">
					<input className='m_input' placeholder='Name' type="text" name="name" required></input>
					<input className='m_input' placeholder='Email' type="text" name="email" required></input>
					<textarea className='m_input' placeholder='Message' style={{height:"150px"}} type="text" name="message" required></textarea>
					<button className='m_hero_cta' style={{marginTop:"0"}} type="submit">Submit</button>
				</form>
			</div>
			<Footer/>
		</div>
	)
}
